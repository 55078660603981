<script lang="ts" setup>
const props = defineProps({
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  dark: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const logoUrl = computed(() => {
  return props.dark ? '/images/fynn-logo-dark.svg' : '/images/fynn-logo-test.svg';
});
</script>
<template>
  <img :src="logoUrl" alt="Fynn Logo" />
</template>
<style lang="scss">
.fynn-logo {
  &--loading {
    .fynn-logo__square {
      // animation pulse
      animation: fynnLogoAnimation 3s infinite linear;
    }
  }
}

@keyframes fynnLogoSpin {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}


@keyframes fynnLogoAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
</style>
